$ERROR_RED: #ff6e6e;
$GREEN_COLOR: #3fd184;
$MAIN_COLOR: #3d578d;
$MAIN_DARK_COLOR: #24203d;
$DARKER_MAIN_COLOR: #24203d;
$MAIN_LIGHT_COLOR: #7a7afc;
$FOOTER_BACKGROUND_COLOR: #24203d;
$GRAY_COLOR: #c2c2c2;
$CHART_START_COLOR: #7a7afc;

:export {
  chart_start_color: $CHART_START_COLOR;
  main_color: $MAIN_COLOR;
}
