@import '_variables.scss';

@font-face {
  font-family: 'Avenir Light';
  font-style: normal;
  font-weight: 300;
  src: url('~@launchpad/fonts/AvenirLTStd-Book.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Avenir Normal';
  font-style: normal;
  font-weight: 500;
  src: url('~@launchpad/fonts/AvenirLTStd-Roman.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Avenir Bold';
  font-style: normal;
  font-weight: 900;
  src: url('~@launchpad/fonts/AvenirLTStd-Black.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Credit-Card-Font';
  font-style: normal;
  font-weight: 500;
  src: url('~@launchpad/fonts/Credit-Card-Font.ttf'); /* IE9 Compat Modes */
}
.noscroll {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Avenir Normal';
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0 !important;
  padding: 0;
}
ul,
li {
  list-style-type: none;
}

.center-text {
  text-align: center;
}

.logo {
  width: 120px;
  height: 80px;
}

.login-overlay-container {
  min-height: 100vh;
  height: 100%;
  padding: 50px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .overlay-background {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    max-height: 100%;
    position: absolute;
    opacity: 0.5;
    top: 0;
    right: -15%;
    bottom: 0;
    z-index: -1;
  }
  .sign-up-holder {
    display: flex;
    flex-direction: row;
    p {
      color: white;
      font-size: 18px;
      padding-right: 5px;
      margin: 0;
    }
    .sign-up-link {
      font-size: 18px;
      font-family: 'Avenir Bold';
      color: white;
      text-decoration: none !important;
    }
  }
  .svg-container {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .center-element {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 200px;
  }
  h1 {
    font-size: 50px;
  }
  h3 {
    font-family: 'Avenir Light';
    font-size: 20px;
    padding-top: 25px;
    padding-bottom: 35px;
    color: $MAIN_DARK_COLOR;
  }
}

.gradient-button {
  width: 100%;
  background-color: $MAIN_LIGHT_COLOR; /* For browsers that do not support gradients */
  background-image: linear-gradient($MAIN_LIGHT_COLOR, $MAIN_COLOR);
  border-radius: 5px;
  background-color: white;
  min-height: 55px;
  max-width: 310px;
  padding: 2px;
  .custom-btn {
    border-radius: 5px;
    background-color: white;
    color: $MAIN_COLOR;
    width: 100%;
    height: 100%;
    min-height: 55px;
    outline: none !important;
    font-size: 16px;
    text-transform: uppercase;
    &:hover {
      background-color: white;
    }
  }
  .custom-inverted-btn {
    border-radius: 5px;
    background-color: $MAIN_COLOR;
    color: white;
    width: 100%;
    height: 100%;
    min-height: 55px;
    outline: none !important;
    font-size: 16px;
    text-transform: uppercase;
    &:hover {
      background-color: $MAIN_COLOR;
    }
  }
}
.gradient-btn-custom {
  background-image: none !important;
}

.inner {
  padding-left: 132px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.screen-layout {
  width: 100%;
  background-color: $MAIN_LIGHT_COLOR; /* For browsers that do not support gradients */
  background-image: linear-gradient(
    336.92deg,
    rgba(17, 190, 254, 0.85) 0%,
    rgba(15, 94, 246, 0.85) 100%,
    rgba(15, 94, 246, 0.85) 100%
  );
  min-height: 100vh;
  height: 100%;
  padding: 120px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .logo {
    position: absolute;
    top: -115px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .app-card {
    background-color: white;
    width: 100%;
    height: 100%;
    min-height: 700px;
    max-width: 1220px;
    box-shadow: 10px 7px 18px 0 rgba(85, 84, 173, 0.5);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 160px;
    padding-top: 60px;
  }
  .svg-container {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .sign-up-holder {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    p {
      color: white;
      font-size: 18px;
      padding-right: 5px;
      margin: 0;
    }
    .sign-up-link {
      font-size: 18px;
      font-family: 'Avenir Bold';
      color: white;
      text-decoration: none !important;
    }
  }
}
.child-container {
  z-index: 99999;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 35px;
    text-align: center;
    letter-spacing: 0.22px;
    line-height: 42px;
    color: $MAIN_DARK_COLOR;
    margin: 0;
    padding-bottom: 27px;
    @media (max-width: 545px) {
      margin-bottom: 30px;
      font-size: 30px;
    }
  }
  p {
    color: $GRAY_COLOR;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 27px;
  }
}
.validation-message {
  font-size: 12px;
  color: $ERROR_RED;
}
.input-group .validation-message {
  position: absolute;
  bottom: -16px;
  left: 0;
}

.login-form {
  width: 100%;
}
#send-money-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.login-form .input-group {
  margin: 0;
  padding: 0;
}
.login-form .login-input-wrapper {
  margin-bottom: 20px;
  position: relative;
  img.input-icon {
    position: absolute;
    z-index: 1;
    top: 25px;
  }
}
.form-wrapper {
  width: 100%;
  max-width: 310px;
  min-width: 310px;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
  }
  .form-button-container {
    display: flex;
    justify-content: center;
  }
  .submitButtonWrapper {
    margin-top: 30px;
  }
}

.squaredTwo {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fff;
  border: 1px solid #979797;
  label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    left: 4px;
    top: 4px;
    color: #9b9a9b;
    &:after {
      content: '';
      width: 14px;
      height: 8px;
      position: absolute;
      top: 3px;
      left: 2px;
      transition: opacity 0.3s ease;
      border: 3px solid $DARKER_MAIN_COLOR;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
    &:hover::after {
      opacity: 0.3;
    }
  }
  input[type='checkbox'] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}

/* React Modal - START */

.ReactModal__Body--open {
  overflow: hidden;
}

// Modal
.react-modal-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 999999999;
}

.react-modal-content {
  padding: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lock-card-modal {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h3 {
    color: $MAIN_COLOR;
  }
  .close-btn {
    @media (max-width: 450px) {
      min-height: 540px;
    }
    width: 100%;
    text-align: right;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-height: 35px;
  }
  i {
    color: $MAIN_COLOR;
  }
}

.transaction-modal {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
  max-width: 620px;
  max-height: 700px;
  min-height: 620px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .close-btn {
    width: 100%;
    text-align: right;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-height: 35px;
  }
  .icon-holder {
    background-color: $GREEN_COLOR;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 10px 0;
  }
  .tx-amount {
    color: $MAIN_DARK_COLOR;
    font-size: 68px;
    font-weight: 800;
    line-height: 82px;
    text-align: center;
    margin-top: 20px;
  }
  h4 {
    text-align: center;
    margin-bottom: 10px;
  }

  .transaction-details {
    width: 100%;
    margin: 20px 0;

    > .row {
      width: 100%;
      margin: 0 !important;
      min-width: 300px;
    }

    .detail-label {
      flex: 1;
      text-align: right;
      padding-right: 5px;
    }

    .detail-value {
      flex: 1;
      text-align: left;
      padding-left: 5px;
    }
  }
}
/* React Modal - END */

.toast-container {
  z-index: 999999999 !important;
}
.toast-mesaage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 99999;
  display: flex;
  align-items: center;
  box-shadow: 10px 7px 18px 0 rgba(85, 84, 173, 0.5);
  height: 60px;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  p {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $MAIN_COLOR;
    cursor: pointer;
  }
}

.top-bar {
  height: 65px;
  background: $MAIN_COLOR;
  max-width: calc(100% - 132px);
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 90px;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 999999;
  @media (max-width: 768px) {
    max-width: 100%;
    padding-right: 10px;
    margin: 0;
  }
}
.phone-menu-holder {
  width: 60px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  z-index: 999999999;
  left: 0;
  top: 0;
  @media (min-width: 769px) {
    display: none;
  }
  .phone-menu-icon {
    width: 30px;
    height: 3px;
    background-color: #ffffff;
    margin: 3px 0;
  }
}
.top-bar-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .profile-image-holder {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
  }
  h4 {
    color: #ffffff;
    font-size: 16px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
  }
  p {
    color: #ffffff;
    font-size: 10px;
    text-align: left;
    margin: 0;
  }
  .name-holder {
    padding-left: 10px;
  }
  .white-line {
    background-color: white;
    height: 28px;
    width: 1px;
    margin: 0 22px;
    @media (max-width: 768px) {
      margin: 0 10px;
    }
  }
}
.app-footer {
  min-height: 305px;
  background: $FOOTER_BACKGROUND_COLOR;
  width: 100%;
  padding: 50px 0;
  position: absolute;
  z-index: 999999;
  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    h3 {
      color: #ffffff;
      font-size: 18px;
      text-align: left;
      text-transform: uppercase;
      padding-bottom: 20px;
    }
    .footer-link {
      color: #ffffff;
      font-size: 16px;
      text-align: left;
      text-decoration: none !important;
      padding-bottom: 8px;
    }
    .pannovate-logo {
      padding-top: 100px;
      @media (max-width: 768px) {
        padding-top: 20px;
      }
      p {
        font-style: italic;
        color: #ffffff;
        font-size: 14px;
        text-align: left;
      }
      img {
        width: 170px;
        height: auto;
      }
    }
  }
  .last-line {
    position: absolute;
    bottom: 55px;
    padding-left: 15px;
    @media (max-width: 768px) {
      bottom: 10px;
    }
    p {
      color: #ffffff;
      font-size: 16px;
      text-align: left;
    }
  }
}
.mobile-side-bar {
  left: 0 !important;
}
.phone-overlay {
  width: calc(100vw - 132px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 132px;
  top: 0;
  @media (min-width: 768px) {
    display: none;
  }
}
.side-bar {
  height: 100%;
  background: white;
  width: 132px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 4px 5px 11px 0 rgba(77, 99, 146, 0.09);
  z-index: 999;
  transition: left 0.3s linear;
  @media (max-width: 768px) {
    left: -140px;
    top: 65px;
  }
  .dash-logo {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100px;
      height: auto;
    }
  }
  .sideMenuList {
    margin: 0;
    padding: 0;
    li {
      height: 125px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: $MAIN_COLOR;
        i {
          color: white;
        }
      }
      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none !important;
      }
      i {
        font-size: 36px;
        color: $MAIN_COLOR;
      }
    }
    .active {
      position: relative;
    }
    .active-blue-line {
      width: 6px;
      height: 100%;
      position: absolute;
      top: 0;
      right: -6px;
      background-color: $DARKER_MAIN_COLOR;
    }
  }
  .side-bar-content-wrapper {
    position: sticky;
    top: 0;
    left: 0;
  }
}
.side-bar-top {
  top: 0;
  transition: top 0.3s linear;
}

.dashboard-main {
  width: 100%;
  position: relative;
  padding-bottom: 120px;
  min-height: 70vh;
  @media (max-width: 768px) {
    padding: 64px 0;
  }
  .svg-container {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}
.dashboard-header {
  width: 100%;
  // min-height: 400px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 65px;
  @media (max-width: 768px) {
    padding-top: 0;
  }
  .totalBalances {
    width: 100%;
    padding-top: 20px;
    border-top: solid 1px $MAIN_COLOR;
  }
  .progress-text {
    color: $MAIN_COLOR;
    font-size: 16px;
    padding: 0 10px;
  }
  .balance-holder {
    width: 100%;
    // max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 20px;
    .total-holder {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .total-item-holder {
        flex: 1;
        text-align: center;
      }
      h3 {
        color: $MAIN_COLOR;
        font-size: 16px;
        font-weight: 400;
      }
      h1 {
        color: $MAIN_DARK_COLOR;
        font-weight: 600;
        text-align: center;
        padding-top: 10px;
      }
      h5 {
        color: $GRAY_COLOR;
        font-size: 13px;
        text-align: center;
      }
      h4 {
        color: $MAIN_DARK_COLOR;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        padding-top: 10px;
      }
    }
  }
}
.send-load-btn-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.send-load-btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $MAIN_DARK_COLOR;
  text-transform: uppercase;
  max-width: 120px;
  margin-right: 10px;
  margin-left: 10px;
  // width: 100%;
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    color: $MAIN_COLOR !important;
  }
  .send-load-icon-wrapper {
    background-color: $MAIN_COLOR;
    border-radius: 26px;
    box-shadow: 0 3px 5px 0 rgba(113, 113, 113, 0.37);
    width: 52px;
    height: 52px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 5px;
    text-decoration: none !important;
    color: $MAIN_DARK_COLOR;
    &:hover {
      color: $MAIN_COLOR !important;
    }
  }
}

.appContainer {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.app-widget {
  height: 500px;
  max-width: 600px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(31, 37, 158, 0.24);
  margin: 10px auto;
  @media (max-width: 992px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .card-header {
    background-color: $MAIN_COLOR;
    height: 55px;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 9px 0 rgba(54, 54, 54, 0.23);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      color: #ffffff;
      font-size: 18px;
      text-align: left;
      padding-left: 15px;
      opacity: 1;
    }
  }
  .card-content {
    width: 100%;
  }
}

.notificationItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid rgba(216, 216, 216, 0.5);
  min-height: 80px;

  &.declined {
    background: #c1c1c1;
  }
  p {
    color: $MAIN_DARK_COLOR;
    font-size: 14px;
  }

  span {
    padding: 0 12px;
    word-break: break-all;
    font-size: 13px;

    @media (max-width: 545px) {
      padding: 0 5px;
    }
  }
}

.notificationItem:first-child {
  border: none;
}
.transactionItem:first-child {
  border: none;
}

.transactionItem {
  background: white;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  @media (max-width: 545px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  &:hover {
    background: #f5f5f5;
  }

  &.statements {
    min-height: auto !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: 80px !important;
  }

  .iconHolder {
    display: flex;
    height: auto;
    min-width: 40px;
    background: $MAIN_COLOR;
    border: 1px solid $MAIN_COLOR;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }
  .transaction-title {
    color: $MAIN_DARK_COLOR !important;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    @media (max-width: 450px) {
      font-size: 12px;
    }
  }
  .transaction-date {
    color: $MAIN_DARK_COLOR;
    font-size: 14px;
    opacity: 0.5;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    @media (max-width: 450px) {
      font-size: 10px;
    }
  }
  .transaction-amount-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .amount {
      flex: 1;
      font-size: 20px;
      align-self: flex-end;
      text-align: right;
      font-weight: 600;
      color: $MAIN_DARK_COLOR;
      @media (max-width: 450px) {
        font-size: 12px;
      }
    }
  }
}

.go-to-inbox {
  position: absolute;
  right: 30px;
  bottom: 10px;
  color: $MAIN_COLOR;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    color: $MAIN_COLOR;
  }
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.amount-input {
  border: none;
  background: transparent;
  outline: none;
  font-size: 30px;
  text-align: center;
  color: $MAIN_DARK_COLOR;
  max-width: 100%;
  border-bottom: 2px solid #d8d8d8;

  &::-webkit-input-placeholder {
    color: #fff !important;
  }

  &::-moz-placeholder {
    color: #fff !important;
  }

  &::-moz-placeholder {
    color: #fff !important;
  }

  &::-ms-input-placeholder {
    color: #fff !important;
  }
}

.account-holder {
  margin-top: 100px;
}

.account-holder,
.terms-holder {
  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

.profile-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(31, 37, 158, 0.24);
  height: 100%;
  padding: 40px;
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 450px) {
    padding: 10px;
  }
  h1 {
    color: $MAIN_COLOR;
    font-size: 20px;
    font-weight: 500;
  }
  h4 {
    color: $MAIN_DARK_COLOR;
    font-size: 18px;
    font-weight: 500;
    padding: 30px 0;
  }
  .owner-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 998px) {
      flex-direction: column;
      justify-content: center;
    }
    .profile-img-name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 998px) {
        margin-bottom: 30px;
      }
      @media (max-width: 500px) {
        flex-direction: column;
        justify-content: center;
      }
      .profile-image-holder {
        border-radius: 60px;
        box-shadow: 0 2px 5px 0 rgba(189, 189, 189, 0.5);
        width: 120px;
        height: 120px;
        overflow: hidden;
        display: flex;
      }
      .owner-name {
        margin-left: 20px;
        @media (max-width: 500px) {
          margin-top: 30px;
          margin-left: 0;
        }
        p {
          color: $MAIN_DARK_COLOR;
          font-size: 14px;
          opacity: 0.5;
          @media (max-width: 500px) {
            text-align: center;
          }
        }
        h3 {
          color: $MAIN_DARK_COLOR;
          font-size: 30px;
          line-height: 40px;
          opacity: 0.9;
          @media (max-width: 500px) {
            text-align: center;
          }
        }
      }
    }
  }
  .info-wrapper {
    .profile-header {
      background: $MAIN_COLOR;
      border-radius: 5px;
      padding: 10px 35px;
      h3 {
        color: #ffffff;
        font-size: 18px;
        font-weight: 800;
      }
    }
    ul {
      padding-top: 18px;
      li {
        color: $MAIN_DARK_COLOR;
        opacity: 0.7;
        font-size: 16px;
        text-align: left;
        span {
          color: black !important;
          opacity: 1 !important;
        }
      }
      .red-info {
        color: $ERROR_RED;
        font-style: italic;
      }
    }
  }
  .privacy-text {
    color: $MAIN_DARK_COLOR;
    font-size: 20px;
    font-weight: 500;
    .terms-link {
      color: $MAIN_DARK_COLOR !important;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 2px solid $MAIN_DARK_COLOR;
      text-decoration: none !important;
    }
  }
}

.card-navigation-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  @media (max-width: 500px) {
    flex-direction: column;
    margin: 0 auto;
  }
  .card-not-active-overlay {
    width: 100%;
    max-width: 375px;
    height: 100%;
    background-color: $MAIN_COLOR;
    border-radius: 11px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.23),
      inset 0 -9px 9px 0 rgba(0, 0, 0, 0.5);
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 8;
    h3 {
      color: #ffffff;
      font-size: 22px;
      font-weight: 800;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
.card-page-card-image {
  max-width: 375px;
  border-radius: 16px;
  // margin-left: 50px;
  max-height: 240px;
  height: auto;
  width: 100%;
}
.card-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 100px;
  min-height: 90vh;
  @media (max-width: 500px) {
    padding-top: 50px;
  }
}
.card-page-card-navigation {
  width: 50px;
  @media (max-width: 500px) {
    transform: rotate(90deg);
    position: relative;
    top: -60px;
  }
  .not-active {
    pointer-events: none;
    opacity: 0.5;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      width: 60px;
      height: 60px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: width 0.25s ease-out;
      cursor: pointer;
      i {
        color: $MAIN_COLOR;
      }
      &:hover {
        width: 100px;
        border-radius: 0 13px 13px 0;
        border: 1px solid $MAIN_COLOR;
        background-color: $MAIN_COLOR;
        i {
          color: white !important;
        }
      }
      &.active {
        width: 100px;
        border: 1px solid $MAIN_COLOR;
        background-color: $MAIN_COLOR;
        border-radius: 0 13px 13px 0;
        i {
          color: white !important;
        }
      }
    }
  }

  li:nth-child(1) {
    border-right: 1px solid $MAIN_COLOR;
    border-top: 1px solid $MAIN_COLOR;
    border-bottom: 1px solid $MAIN_COLOR;
    border-top-right-radius: 13px;
  }
  li:nth-child(2) {
    border-right: 1px solid $MAIN_COLOR;
  }
  li:nth-child(3) {
    border-right: 1px solid $MAIN_COLOR;
    border-top: 1px solid $MAIN_COLOR;
    border-bottom: 1px solid $MAIN_COLOR;
    border-bottom-right-radius: 13px;
  }
}

.details-holder {
  padding: 50px 15px 15px;
  position: relative;
  .top-line {
    max-width: 400px;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    transform: translate(-50%, 0);
  }
  ul {
    padding: 0;
  }
  li {
    color: $MAIN_DARK_COLOR;
    padding-bottom: 24px;
  }
  .control-card li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .link-for-terms {
    color: $MAIN_DARK_COLOR;
    font-size: 16px;
    text-decoration: none !important;
    border-bottom: 1px solid $MAIN_DARK_COLOR;
  }
  h3 {
    color: $MAIN_DARK_COLOR;
    font-size: 18px;
    text-align: center;
  }
  p {
    color: $MAIN_DARK_COLOR;
    font-size: 14px;
    opacity: 0.5;
    text-align: center;
  }
}

.switch-container {
  position: relative;
  display: flex;
  align-items: center;
}

input[type='checkbox'].switch {
  position: absolute;
  opacity: 0;
}

input[type='checkbox'].switch + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 999px;
  background-color: #d5d5d5;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type='checkbox'].switch + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type='checkbox'].switch + div span:nth-child(1) {
  margin-left: 15px;
}

input[type='checkbox'].switch + div span:nth-child(2) {
  margin-left: 55px;
}

input[type='checkbox'].switch:checked + div {
  width: 47px;
  background-position: 0 0;
  background-color: $GREEN_COLOR;
}

input[type='checkbox'].switch + div {
  width: 47px;
  height: 24px;
}

input[type='checkbox'].switch:checked + div {
  background-color: $GREEN_COLOR;
}

input[type='checkbox'].switch + div > div {
  float: left;
  width: 22px;
  height: 22px;
  margin: 1px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
}

input[type='checkbox'].switch:checked + div > div {
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
  background-color: #ffffff;
}

input[type='checkbox'].bigswitch.switch + div > div {
  width: 22px;
  height: 22px;
  margin-top: 1px;
}

input[type='checkbox'].switch:checked + div > div {
  -webkit-transform: translate3d(23px, 0, 0);
  transform: translate3d(23px, 0, 0);
}

.pin-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 60px;
  margin-left: 30px;
  @media (max-width: 450px) {
    margin-left: 15px;
  }
  .pin-number-holder {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border: 1px solid $MAIN_COLOR;
    border-radius: 4px;
    min-height: 64px;
    max-width: 64px;
    position: relative;
    span {
      color: $MAIN_DARK_COLOR;
      font-size: 40px;
      font-weight: 500;
      text-align: center;
    }
  }
  h4 {
    color: $MAIN_DARK_COLOR;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    min-width: 52px;
    text-align: center;
  }
}

.circle-progress {
  color: $MAIN_COLOR;
}

.light {
  color: white !important;
}

.stat-holder {
  h3 {
    color: $MAIN_DARK_COLOR;
    font-size: 20px;
    font-weight: 500;
  }
  p {
    color: $MAIN_DARK_COLOR;
    font-size: 18px;
    opacity: 0.5;
  }
  span {
    color: $MAIN_DARK_COLOR;
    font-size: 18px;
    font-weight: 600;
  }
}
.last-trans-overlay {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 80px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.6),
    white,
    white
  );
}
.chart-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 50px;
  span {
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
    margin: 0 10px 0 10px;
    color: $GRAY_COLOR;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: white !important;
      background: $MAIN_COLOR;
      border: none !important;
    }

    &.active {
      color: white !important;
      background: $MAIN_COLOR;
      border: none !important;
    }

    @media (max-width: 545px) {
      padding: 5px 8px;
    }
  }
}

.statements-transaction-wrapper {
  padding-right: 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 992px) {
    border: none;
    padding: 0;
    margin-bottom: 50px;
  }
}

.filter-container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  justify-content: space-between;
  h3 {
    color: $MAIN_DARK_COLOR;
    font-size: 17px;
    opacity: 0.6;
  }
  .btn-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    span {
      border-radius: 5px;
      border: 1px solid $MAIN_LIGHT_COLOR;
      width: 90px;
      height: 45px;
      font-size: 15px;
      color: $MAIN_DARK_COLOR;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        color: white !important;
        background: $MAIN_COLOR;
        border: none !important;
      }

      &.active {
        color: white !important;
        background: $MAIN_COLOR;
        border: none !important;
      }

      @media (max-width: 545px) {
        padding: 5px 8px;
      }
    }
  }
}
.datePickerHolder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0;
  .statement-date-text {
    color: $MAIN_DARK_COLOR;
    opacity: 0.5;
    min-width: 50px;
    font-size: 15px;
    font-weight: 500;
  }
  .statement-date {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 5px;
    color: $MAIN_DARK_COLOR;
  }
  .react-datepicker__close-icon::after {
    background-color: $MAIN_COLOR !important;
  }
}
.search-wrapper {
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  i.fa-search {
    position: absolute;
    color: $MAIN_DARK_COLOR;
    opacity: 0.6;
    top: 10px;
    left: 15px;
  }

  input {
    background: transparent;
    padding-left: 50px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: $MAIN_DARK_COLOR;
    height: 38px;
    width: 100%;

    &::-moz-placeholder {
      color: rgba(0, 0, 0, 0.3) !important;
    }

    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.3) !important;
    }

    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.3) !important;
    }
  }
}
.filter-button-wrapper {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  // border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  padding-top: 20px;
}

.load-title {
  color: $MAIN_DARK_COLOR;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.load-subtitle {
  color: $MAIN_DARK_COLOR;
  font-size: 18px;
  text-align: center;
  opacity: 0.9;
  max-width: 450px;
}
.load-info {
  margin: 20px 0;
  .column {
    display: flex;
    flex-direction: column;
  }
  .first-line {
    span {
      color: $DARKER_MAIN_COLOR;
      font-size: 16px;
      text-align: left;
      padding-bottom: 10px;
    }
  }
  .second-line {
    span {
      color: $MAIN_COLOR;
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 10px;
    }
  }
}
.customer-no {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    color: $MAIN_COLOR;
    font-size: 16px;
    text-align: center;
  }
  .ref-number-holder {
    color: #ffffff;
    font-size: 38px;
    text-align: center;
    margin: 10px 0;
    padding: 20px;
    background-image: linear-gradient(
      180deg,
      $MAIN_LIGHT_COLOR 0%,
      $MAIN_COLOR 100%
    );
    border-radius: 5px;
    width: 100%;
    max-width: 450px;
  }
  h6 {
    color: $MAIN_DARK_COLOR;
    opacity: 0.6;
    font-size: 14px;
    font-style: italic;
    text-align: center;
    max-width: 450px;
  }
}

.black-btn {
  border: 2px solid $MAIN_DARK_COLOR;
  border-radius: 25.5px;
  outline: none !important;
  background-color: transparent;
  color: $MAIN_DARK_COLOR;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  max-width: 308px;
  min-height: 51px;
  width: 100%;
  height: 100%;
  font-family: 'Proxima-Nova Normal' !important;
  text-transform: uppercase;
  &:hover {
    background-color: transparent;
  }
}
.full-black-btn {
  background-color: $MAIN_DARK_COLOR;
}

.modal-icon {
  color: $MAIN_COLOR;
}

// NOTIFICATIONS PAGE

.notifications-header {
  display: flex;
  justify-content: flex-end;
  .select-all-button {
    position: relative;
    background: $MAIN_COLOR;
    border-radius: 50px;
    padding: 10px 30px;
    outline: none !important;
    border: 1px solid $MAIN_COLOR;
    span {
      color: #ffffff;
      font-size: 15px;
      font-weight: bold;
    }
  }

  .notifications-dropdown-button {
    position: relative;
    background: white;
    border-radius: 50px;
    padding: 10px 30px;
    outline: none !important;
    border: 1px solid $MAIN_COLOR;
    span {
      color: $MAIN_DARK_COLOR;
      font-size: 15px;
    }
  }
}

.dropdown-toggle::after {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 12px;
  content: '';
  border-top: 0.3em solid $MAIN_COLOR !important;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.notifications-col-12 {
  padding: 15px;
}

.notifications-dropdown {
  margin-left: 20px;

  .dropdown-menu {
    border-radius: 10px;
    border-color: #cfd1d7;

    li {
      color: $DARKER_MAIN_COLOR;
      padding: 7px !important;
      font-size: 12px !important;
    }
  }

  #moreDropdown {
    right: -38px;
  }

  .check-icon {
    position: absolute;
    right: 1px;
  }
}

.notifications-body {
  height: 70vh;
}

.inner-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    color: $MAIN_DARK_COLOR;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  p {
    color: $MAIN_DARK_COLOR;
    font-size: 16px;
    text-align: center;
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
  img {
    max-width: 400px;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-top: 50px;
  }
}
.input-wrapper {
  max-width: 500px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .input-pin {
    max-width: 5%;
    margin: 10px;
    flex: 1;
    border: none;
    background: transparent;
    border-bottom: 1px solid $MAIN_COLOR;
    text-align: center;
    font-size: 24px;
    color: $MAIN_DARK_COLOR;
    outline: none;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: gray !important;
  border-radius: 10px !important;
}
#outlined-error {
  .MuiOutlinedInput-notchedOutline {
    border-color: $ERROR_RED !important;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: inherit !important;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.report-number {
  text-decoration: none !important;
  font-size: 22px;
  color: $MAIN_COLOR;
  &:hover {
    color: $MAIN_LIGHT_COLOR;
  }
}

.fx-calculator {
  @media (min-width: 768px) {
    margin-top: 100px;
  }
}

.exchange-fee-title {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: $MAIN_COLOR;
}

.change-password-form-wraper {
  width: 100%;
  max-width: 500px;
  > div {
    width: 100%;
    max-width: 500px;
  }
  .submitButtonWrapper {
    .gradient-button {
      margin: auto;
    }
  }
}

.cms-content {
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 10px;
    margin-top: 30px;
    font-weight: bold;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 18px;
  }

  p {
    margin: 5px;
  }

  p {
    margin: 10px 0;
  }

  .text-center {
    text-align: center;
  }

  .text-bold {
    font-weight: bold;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
  }

  table.bordered td,
  table.bordered th {
    border: 1px solid black;
  }

  table tr th {
    background-color: #b7b7b7;
    padding: 5px;
  }

  table tr td {
    padding: 5px;
  }

  table tr.noBorder th:first-child {
    border-right: none;
  }

  strong {
    font-weight: bold !important;
  }

  ul {
    // list-style: none;
    margin: 0;
    padding: 0;
    list-style: disc !important;
    list-style-type: disc !important;
    list-style-position: inside !important;
  }

  ul li {
    margin: 10px 0;
    list-style-type: disc !important;
  }

  ul li .number {
    display: flex;
    font-weight: bold;
  }

  ul li .content {
    margin-left: 10px;
  }

  ul.bulletted {
    padding-left: 20px;
    margin-left: 10px;
    list-style: disc !important;
    list-style-type: disc !important;
  }

  ul.bulletted li {
    display: list-item;
    list-style: disc !important;
    list-style-type: disc !important;
  }
}

.legal-title {
  padding-top: 80px;
  @media (max-width: 768px) {
    padding-top: 20px;
    font-size: 1.8rem;
  }
}

.Collapsible {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid gray;
  padding-bottom: 20px;
  position: relative;
  font-size: 18px;
  span {
    color: $MAIN_COLOR;
    cursor: pointer;
  }
  .full-text {
    font-weight: 300;
  }
}

.ql-size-huge,
.ql-size-large {
  font-size: 20px !important;
}
