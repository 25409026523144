@import '_variables.scss';
@import '~@launchpad/styles/style.scss';

@font-face {
  font-family: 'WorkSans Light';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/WorkSans-Light.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'WorkSans Normal';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/WorkSans-Regular.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'WorkSans Bold';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/WorkSans-Bold.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'WorkSans ExtraBold';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/WorkSans-Black.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Credit-Card-Font';
  font-style: normal;
  font-weight: 500;
  src: url('../../../@launchpad/fonts/Credit-Card-Font.ttf'); /* IE9 Compat Modes */
}

body {
  font-family: 'WorkSans Normal' !important;
}

.logo {
  width: 170px !important;
  height: auto !important;
  z-index: 99;
  top: -100px !important;
  @media (max-width: 500px) {
    top: 10px !important;
  }
}

.login-overlay-container {
  background-color: 'white';
  .top-element {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .svg-container {
    z-index: 1 !important;
  }
  .center-element {
    padding-bottom: 0 !important;
    h1 {
      color: $MAIN_DARK_COLOR;
      font-size: 50px;
      font-weight: 700;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 36px;
      }
    }
    h3 {
      color: $MAIN_DARK_COLOR;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      font-family: 'WorkSans Light';
      max-width: 600px;
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
}

.circle-progress {
  color: $MAIN_COLOR !important;

  &.light {
    color: white !important;
  }
}
.form-wrapper {
  @media (max-width: 320px) {
    min-width: 280px !important;
  }
}

.gradient-btn-custom {
  background-image: none !important;
}

.screen-layout {
  background-color: white !important;
  background-image: none !important;
  @media (max-width: 500px) {
    padding: 10px !important;
  }
  .app-card {
    padding: 0 !important;
    min-height: 600px !important;
    box-shadow: 10px 7px 18px 0 rgba(0, 0, 0, 0.3) !important;
  }
}

.transaction-status-completed {
  max-height: 550px !important;
  min-height: 500px !important;
}
